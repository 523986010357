import React from 'react';
import Layout from '../components/layout';
import { Link } from 'gatsby';
import SEO from '../components/utils/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <div className="wrap">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="text-center py-4">
              <h1>Aw Crap. I changed some stuff and 404'd you.</h1>
              <p>Try another page or wait to let the cat figure it out.</p>
              <div className="py-3">
                <Link className="post_link" to="/">
                  The cat has logged it. Go Back Home Or Check Posts Below.
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
